import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import Form from "./Form";
import { usePageContext } from "../../providers/PageContext";

const GeneralContactFormWidget = ({ containerSettings }) => {
  const pageContext = usePageContext();
  const departments = pageContext.departments;

  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <Form departments={departments} />
          </div>
        </div>
      </div>
    </Container>
  );
};

GeneralContactFormWidget.title = "General Contact Form";
GeneralContactFormWidget.category = "Forms";

GeneralContactFormWidget.defaults = {
  containerSettings: {},
};

export default GeneralContactFormWidget;
