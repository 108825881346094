import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../../frontend/UncontrolledInput";
import { AuthTokenContext } from "../../providers";

const Form = ({ departments }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState("");
  const [formData, setFormData] = React.useState({});
  const token = React.useContext(AuthTokenContext);

  const safeDepartments = departments.map((st) => ({
    id: st.id,
    label: st.name,
    value: st.id,
  }));

  const updateData = (key, value) => {
    setFormData((current) => {
      current[key] = value;

      return { ...current };
    });
  };

  return (
    <div className="form">
      <form
        method="POST"
        action="/general-contact"
        onSubmit={() => {
          setSubmitting(true);
        }}
      >
        <Input type="hidden" name="authenticity_token" value={token} />

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[first_name]"
              id="data_first_name"
              label="First Name"
              required
              maxLength="25"
            />
          </div>
          <div className="form-item">
            <Input
              type="text"
              name="data[last_name]"
              id="data_last_name"
              label="Last Name"
              required
              maxLength="25"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="restirctiveText"
              name="data[phone]"
              id="data_phone"
              label="Phone"
              maxLength="10"
              allowedCharacters={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ]}
              placeholder="Please enter a 10 digit phone number"
            />
          </div>

          <div className="form-item">
            <Input
              type="email"
              name="data[email]"
              id="data_email"
              label="Email"
              required
            />
          </div>
        </div>

        {safeDepartments.length > 0 && (
          <div className="form-row">
            <div className="form-item">
              <Input
                type="select"
                name="data[department]"
                label="Departments"
                placeholder=""
                id="data_department"
                options={safeDepartments}
                required
                onChange={(event) => {
                  updateData("department", event.target.value);
                }}
              />
            </div>
          </div>
        )}

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[subject]"
              id="data_subject"
              label="Subject"
              required
              maxLength="25"
            />
          </div>
          <div className="form-item">
            <Input
              type="textarea"
              name="data[comments]"
              id="data_comments"
              label="Comments"
              required
            />
          </div>
        </div>

        <div className="center">
          <ReCAPTCHA
            sitekey="6LfOXSUcAAAAAHgluB7FB5JrlEDkeWj9O7lNHULc"
            onChange={(value) => setRecaptcha(value)}
          />
        </div>

        <Input type="hidden" name="g-recaptcha-response" value={recaptcha} />

        <div className="center">
          <button
            type="submit"
            className="btn-primary lg"
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
