import React from "react";
import Input from "../UncontrolledInput";

const StarsForm = ({ token, programs, preselectedProgram }) => {
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <form
      method="POST"
      action="/stars"
      onSubmit={() => {
        setSubmitting(true);
      }}
    >
      <Input type="hidden" name="authenticity_token" value={token} />
      <Input type="hidden" name="stars[form_type]" value="regular" />

      <Input
        type="text"
        name="stars[first_name]"
        id="stars_first_name"
        label="First Name"
        required
        maxLength="12"
      />

      <Input
        type="text"
        name="stars[last_name]"
        id="stars_last_name"
        label="Last Name"
        required
        maxLength="25"
      />

      <Input
        type="restirctiveText"
        name="stars[phone]"
        id="stars_phone"
        label="Phone"
        required
        maxLength="10"
        allowedCharacters={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
        placeholder="Please enter a 10 digit phone number"
      />

      <Input
        type="email"
        name="stars[email]"
        id="stars_email"
        label="Email"
        required
      />

      <Input
        type="select"
        name="stars[program_1]"
        label="Program of interest"
        placeholder={preselectedProgram ? null : ""}
        id="stars_program_1"
        options={programs}
        required
        defaultValue={preselectedProgram}
      />

      <Input
        type="checkbox"
        name="stars[terms]"
        defaultValue="accept"
        required
        labelClass="checkbox-label-grid"
        label={() => (
          <small>
            By clicking the submit button, I give AIMS Education permission to
            call, text, and/or email me information about its educational
            programs at the phone number and email address provided. I
            acknowledge my consent is not required to obtain any good or
            service. I can opt-out by contacting AIMS Education at 908-222-0002.
          </small>
        )}
      />

      <button type="submit" className="btn-primary lg" disabled={submitting}>
        Submit
      </button>
    </form>
  );
};

export default StarsForm;
