const COUNTRIES = [
  { id: "USA", value: "USA", label: "United States of America" },
  { id: "CAN", value: "CAN", label: "Canada" },
  { id: "AFG", value: "AFG", label: "Afghanistan" },
  { id: "ALA", value: "ALA", label: "Aland Islands" },
  { id: "ALB", value: "ALB", label: "Albania" },
  { id: "DZA", value: "DZA", label: "Algeria" },
  { id: "ASM", value: "ASM", label: "American Samoa" },
  { id: "AND", value: "AND", label: "Andorra" },
  { id: "AGO", value: "AGO", label: "Angola" },
  { id: "AIA", value: "AIA", label: "Anguilla" },
  { id: "ATA", value: "ATA", label: "Antarctica" },
  { id: "ATG", value: "ATG", label: "Antigua And Barbuda" },
  { id: "ARG", value: "ARG", label: "Argentina" },
  { id: "ARM", value: "ARM", label: "Armenia" },
  { id: "ABW", value: "ABW", label: "Aruba" },
  { id: "AUS", value: "AUS", label: "Australia" },
  { id: "AUT", value: "AUT", label: "Austria" },
  { id: "AZE", value: "AZE", label: "Azerbaijan" },
  { id: "BHS", value: "BHS", label: "Bahamas" },
  { id: "BHR", value: "BHR", label: "Bahrain" },
  { id: "BGD", value: "BGD", label: "Bangladesh" },
  { id: "BRB", value: "BRB", label: "Barbados" },
  { id: "BLR", value: "BLR", label: "Belarus" },
  { id: "BEL", value: "BEL", label: "Belgium" },
  { id: "BLZ", value: "BLZ", label: "Belize" },
  { id: "BEN", value: "BEN", label: "Benin" },
  { id: "BMU", value: "BMU", label: "Bermuda" },
  { id: "BTN", value: "BTN", label: "Bhutan" },
  { id: "BOL", value: "BOL", label: "Bolivia" },
  { id: "BIH", value: "BIH", label: "Bosnia And Herzegovina" },
  { id: "BWA", value: "BWA", label: "Botswana" },
  { id: "BVT", value: "BVT", label: "Bouvet Island" },
  { id: "BRA", value: "BRA", label: "Brazil" },
  { id: "IOT", value: "IOT", label: "British Indian Ocean Territory" },
  { id: "BRN", value: "BRN", label: "Brunei Darussalam" },
  { id: "BGR", value: "BGR", label: "Bulgaria" },
  { id: "BFA", value: "BFA", label: "Burkina Faso" },
  { id: "BDI", value: "BDI", label: "Burundi" },
  { id: "KHM", value: "KHM", label: "Cambodia" },
  { id: "CMR", value: "CMR", label: "Cameroon" },
  { id: "CPV", value: "CPV", label: "Cape Verde" },
  { id: "CYM", value: "CYM", label: "Cayman Islands" },
  { id: "CAF", value: "CAF", label: "Central African Republic" },
  { id: "TCD", value: "TCD", label: "Chad" },
  { id: "CHL", value: "CHL", label: "Chile" },
  { id: "CHN", value: "CHN", label: "China" },
  { id: "CXR", value: "CXR", label: "Christmas Island" },
  { id: "CCK", value: "CCK", label: "Cocos (Keeling) Islands" },
  { id: "COL", value: "COL", label: "Colombia" },
  { id: "COM", value: "COM", label: "Comoros" },
  { id: "COG", value: "COG", label: "Congo" },
  { id: "COD", value: "COD", label: "Congo, The Democratic Republic Of The" },
  { id: "COK", value: "COK", label: "Cook Islands" },
  { id: "CRI", value: "CRI", label: "Costa Rica" },
  { id: "CIV", value: "CIV", label: "Cote D'Ivoire" },
  { id: "HRV", value: "HRV", label: "Croatia" },
  { id: "CUB", value: "CUB", label: "Cuba" },
  { id: "CYP", value: "CYP", label: "Cyprus" },
  { id: "CZE", value: "CZE", label: "Czech Republic" },
  { id: "DNK", value: "DNK", label: "Denmark" },
  { id: "DJI", value: "DJI", label: "Djibouti" },
  { id: "DMA", value: "DMA", label: "Dominica" },
  { id: "DOM", value: "DOM", label: "Dominican Republic" },
  { id: "ECU", value: "ECU", label: "Ecuador" },
  { id: "EGY", value: "EGY", label: "Egypt" },
  { id: "SLV", value: "SLV", label: "El Salvador" },
  { id: "GNQ", value: "GNQ", label: "Equatorial Guinea" },
  { id: "ERI", value: "ERI", label: "Eritrea" },
  { id: "EST", value: "EST", label: "Estonia" },
  { id: "ETH", value: "ETH", label: "Ethiopia" },
  { id: "FRO", value: "FRO", label: "Faroe Islands" },
  { id: "FLK", value: "FLK", label: "Falkland Islands (Malvinas)" },
  { id: "FJI", value: "FJI", label: "Fiji" },
  { id: "FIN", value: "FIN", label: "Finland" },
  { id: "FRA", value: "FRA", label: "France" },
  { id: "GUF", value: "GUF", label: "French Guiana" },
  { id: "PYF", value: "PYF", label: "French Polynesia" },
  { id: "ATF", value: "ATF", label: "French Southern Territories" },
  { id: "GAB", value: "GAB", label: "Gabon" },
  { id: "GMB", value: "GMB", label: "Gambia, The" },
  { id: "GEO", value: "GEO", label: "Georgia" },
  { id: "DEU", value: "DEU", label: "Germany" },
  { id: "GHA", value: "GHA", label: "Ghana" },
  { id: "GIB", value: "GIB", label: "Gibraltar" },
  { id: "GRC", value: "GRC", label: "Greece" },
  { id: "GRL", value: "GRL", label: "Greenland" },
  { id: "GRD", value: "GRD", label: "Grenada" },
  { id: "GLP", value: "GLP", label: "Guadeloupe" },
  { id: "GTM", value: "GTM", label: "Guatemala" },
  { id: "GGY", value: "GGY", label: "Guernsey" },
  { id: "GIN", value: "GIN", label: "Guinea" },
  { id: "GNB", value: "GNB", label: "Guinea-Bissau" },
  { id: "GUY", value: "GUY", label: "Guyana" },
  { id: "HTI", value: "HTI", label: "Haiti" },
  { id: "HMD", value: "HMD", label: "Heard Island And Mcdonald Islands" },
  { id: "VAT", value: "VAT", label: "Holy See (Vatican City State)" },
  { id: "HND", value: "HND", label: "Honduras" },
  { id: "HKG", value: "HKG", label: "Hong Kong" },
  { id: "HUN", value: "HUN", label: "Hungary" },
  { id: "ISL", value: "ISL", label: "Iceland" },
  { id: "IND", value: "IND", label: "India" },
  { id: "IDN", value: "IDN", label: "Indonesia" },
  { id: "IRN", value: "IRN", label: "Iran, Islamic Republic Of" },
  { id: "IRQ", value: "IRQ", label: "Iraq" },
  { id: "IRL", value: "IRL", label: "Ireland" },
  { id: "IMN", value: "IMN", label: "Isle of Man" },
  { id: "ISR", value: "ISR", label: "Israel" },
  { id: "ITA", value: "ITA", label: "Italy" },
  { id: "JAM", value: "JAM", label: "Jamaica" },
  { id: "JPN", value: "JPN", label: "Japan" },
  { id: "JEY", value: "JEY", label: "Jersey" },
  { id: "JOR", value: "JOR", label: "Jordan" },
  { id: "KAZ", value: "KAZ", label: "Kazakhstan" },
  { id: "KEN", value: "KEN", label: "Kenya" },
  { id: "KIR", value: "KIR", label: "Kiribati" },
  { id: "PRK", value: "PRK", label: "Korea, Democratic People's Republic Of" },
  { id: "KOR", value: "KOR", label: "Korea, Republic Of" },
  { id: "KWT", value: "KWT", label: "Kuwait" },
  { id: "KGZ", value: "KGZ", label: "Kyrgyzstan" },
  { id: "LAO", value: "LAO", label: "Lao" },
  { id: "LVA", value: "LVA", label: "Latvia" },
  { id: "LBN", value: "LBN", label: "Lebanon" },
  { id: "LSO", value: "LSO", label: "Lesotho" },
  { id: "LBR", value: "LBR", label: "Liberia" },
  { id: "LBY", value: "LBY", label: "Libya" },
  { id: "LIE", value: "LIE", label: "Liechtenstein" },
  { id: "LTU", value: "LTU", label: "Lithuania" },
  { id: "LUX", value: "LUX", label: "Luxembourg" },
  { id: "MAC", value: "MAC", label: "Macao" },
  {
    id: "MKD",
    value: "MKD",
    label: "Macedonia, The Former Yugoslav Republic Of",
  },
  { id: "MDG", value: "MDG", label: "Madagascar" },
  { id: "MWI", value: "MWI", label: "Malawi" },
  { id: "MYS", value: "MYS", label: "Malaysia" },
  { id: "MDV", value: "MDV", label: "Maldives" },
  { id: "MLI", value: "MLI", label: "Mali" },
  { id: "MLT", value: "MLT", label: "Malta" },
  { id: "MHL", value: "MHL", label: "Marshall Islands" },
  { id: "MTQ", value: "MTQ", label: "Martinique" },
  { id: "MRT", value: "MRT", label: "Mauritania" },
  { id: "MUS", value: "MUS", label: "Mauritius" },
  { id: "MYT", value: "MYT", label: "Mayotte" },
  { id: "MEX", value: "MEX", label: "Mexico" },
  { id: "MDA", value: "MDA", label: "Moldova, Republic Of" },
  { id: "MCO", value: "MCO", label: "Monaco" },
  { id: "MNG", value: "MNG", label: "Mongolia" },
  { id: "MNE", value: "MNE", label: "Montenegro" },
  { id: "MSR", value: "MSR", label: "Montserrat" },
  { id: "MAR", value: "MAR", label: "Morocco" },
  { id: "MOZ", value: "MOZ", label: "Mozambique" },
  { id: "MMR", value: "MMR", label: "Myanmar" },
  { id: "NAM", value: "NAM", label: "Namibia" },
  { id: "NRU", value: "NRU", label: "Nauru" },
  { id: "NPL", value: "NPL", label: "Nepal" },
  { id: "NLD", value: "NLD", label: "Netherlands" },
  { id: "ANT", value: "ANT", label: "Netherlands Antilles" },
  { id: "NCL", value: "NCL", label: "New Caledonia" },
  { id: "NZL", value: "NZL", label: "New Zealand" },
  { id: "NIC", value: "NIC", label: "Nicaragua" },
  { id: "NER", value: "NER", label: "Niger" },
  { id: "NGA", value: "NGA", label: "Nigeria" },
  { id: "NIU", value: "NIU", label: "Niue" },
  { id: "NFK", value: "NFK", label: "Norfolk Island" },
  { id: "MNP", value: "MNP", label: "Northern Mariana Islands" },
  { id: "NOR", value: "NOR", label: "Norway" },
  { id: "OMN", value: "OMN", label: "Oman" },
  { id: "PAK", value: "PAK", label: "Pakistan" },
  { id: "PLW", value: "PLW", label: "Palau" },
  { id: "PSE", value: "PSE", label: "Palestinian Territory, Occupied" },
  { id: "PAN", value: "PAN", label: "Panama" },
  { id: "PNG", value: "PNG", label: "Papua New Guinea" },
  { id: "PRY", value: "PRY", label: "Paraguay" },
  { id: "PER", value: "PER", label: "Peru" },
  { id: "PHL", value: "PHL", label: "Philippines" },
  { id: "PCN", value: "PCN", label: "Pitcairn" },
  { id: "POL", value: "POL", label: "Poland" },
  { id: "PRT", value: "PRT", label: "Portugal" },
  { id: "QAT", value: "QAT", label: "Qatar" },
  { id: "REU", value: "REU", label: "Reunion" },
  { id: "ROU", value: "ROU", label: "Romania" },
  { id: "RUS", value: "RUS", label: "Russian Federation" },
  { id: "RWA", value: "RWA", label: "Rwanda" },
  { id: "SHN", value: "SHN", label: "Saint Helena" },
  { id: "KNA", value: "KNA", label: "Saint Kitts And Nevis" },
  { id: "LCA", value: "LCA", label: "Saint Lucia" },
  { id: "SPM", value: "SPM", label: "Saint Pierre And Miquelon" },
  { id: "VCT", value: "VCT", label: "Saint Vincent And The Grenadines" },
  { id: "BLM", value: "BLM", label: "Saint-Barthelemy" },
  { id: "MAF", value: "MAF", label: "Saint-Martin (French Part)" },
  { id: "WSM", value: "WSM", label: "Samoa" },
  { id: "SMR", value: "SMR", label: "San Marino" },
  { id: "STP", value: "STP", label: "Sao Tome and Principe" },
  { id: "SAU", value: "SAU", label: "Saudi Arabia" },
  { id: "SEN", value: "SEN", label: "Senegal" },
  { id: "SRB", value: "SRB", label: "Serbia" },
  { id: "SYC", value: "SYC", label: "Seychelles" },
  { id: "SLE", value: "SLE", label: "Sierra Leone" },
  { id: "SGP", value: "SGP", label: "Singapore" },
  { id: "SVK", value: "SVK", label: "Slovakia" },
  { id: "SVN", value: "SVN", label: "Slovenia" },
  { id: "SLB", value: "SLB", label: "Solomon Islands" },
  { id: "SOM", value: "SOM", label: "Somalia" },
  { id: "ZAF", value: "ZAF", label: "South Africa" },
  {
    id: "SGS",
    value: "SGS",
    label: "South Georgia And The South Sandwich Islands",
  },
  { id: "ESP", value: "ESP", label: "Spain" },
  { id: "LKA", value: "LKA", label: "Sri Lanka" },
  { id: "SDN", value: "SDN", label: "Sudan" },
  { id: "SUR", value: "SUR", label: "Suriname" },
  { id: "SJM", value: "SJM", label: "Svalbard And Jan Mayen" },
  { id: "SWZ", value: "SWZ", label: "Swaziland" },
  { id: "SWE", value: "SWE", label: "Sweden" },
  { id: "CHE", value: "CHE", label: "Switzerland" },
  { id: "SYR", value: "SYR", label: "Syrian Arab Republic" },
  { id: "TWN", value: "TWN", label: "Taiwan, Province Of China" },
  { id: "TJK", value: "TJK", label: "Tajikistan" },
  { id: "TZA", value: "TZA", label: "Tanzania, United Republic Of" },
  { id: "THA", value: "THA", label: "Thailand" },
  { id: "TLS", value: "TLS", label: "Timor-Leste" },
  { id: "TGO", value: "TGO", label: "Togo" },
  { id: "TKL", value: "TKL", label: "Tokelau" },
  { id: "TON", value: "TON", label: "Tonga" },
  { id: "TTO", value: "TTO", label: "Trinidad and Tobago" },
  { id: "TUN", value: "TUN", label: "Tunisia" },
  { id: "TUR", value: "TUR", label: "Turkey" },
  { id: "TKM", value: "TKM", label: "Turkmenistan" },
  { id: "TCA", value: "TCA", label: "Turks And Caicos Islands" },
  { id: "TUV", value: "TUV", label: "Tuvalu" },
  { id: "UGA", value: "UGA", label: "Uganda" },
  { id: "UKR", value: "UKR", label: "Ukraine" },
  { id: "ARE", value: "ARE", label: "United Arab Emirates" },
  { id: "GBR", value: "GBR", label: "United Kingdom" },
  { id: "UMI", value: "UMI", label: "United States Minor Outlying Islands" },
  { id: "URY", value: "URY", label: "Uruguay" },
  { id: "UZB", value: "UZB", label: "Uzbekistan" },
  { id: "VUT", value: "VUT", label: "Vanuatu" },
  { id: "VEN", value: "VEN", label: "Venezuela" },
  { id: "VNM", value: "VNM", label: "Viet Nam" },
  { id: "VGB", value: "VGB", label: "Virgin Islands, British" },
  { id: "WLF", value: "WLF", label: "Wallis And Futuna" },
  { id: "ESH", value: "ESH", label: "Western Sahara" },
  { id: "YEM", value: "YEM", label: "Yemen" },
  { id: "ZMB", value: "ZMB", label: "Zambia" },
  { id: "ZWE", value: "ZWE", label: "Zimbabwe" },
  { id: "TWN", value: "TWN", label: "Taiwan" },
  { id: "CUW", value: "CUW", label: "Curacao" },
  { id: "SXM", value: "SXM", label: "Sint Maarten (Dutch part)" },
  { id: "BES", value: "BES", label: "Bonaire, Saint Eustatius and Saba" },
  { id: "XKX", value: "XKX", label: "Kosovo" },
  { id: "SSD", value: "SSD", label: "South Sudan" },
  { id: "FSM", value: "FSM", label: "Micronesia, Federated States of" },
];

export default COUNTRIES;
