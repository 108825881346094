import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import showGlobalNotice from "./showGlobalNotice";
import disableStickyFooter from "./disableStickyFooter";
import LayoutModals from "./LayoutModals";
import { PageContext } from "../providers/PageContext";

const ResponsiveDebug = () => (
  <div className="responsive-debug">
    <div className="phone">phone</div>
    <div className="mobile">mobile</div>
    <div className="tablet">tablet</div>
    <div className="desktop">desktop</div>
    <div className="laptop">laptop</div>
    <div className="tablet-and-desktop">tablet-and-desktop</div>
    <div className="big-screen">big-screen</div>
  </div>
);

export default ({ page, pageContext, children }) => (
  <LayoutModals>
    <Header showGlobalNotice={showGlobalNotice(page)} context={pageContext} />

    <PageContext.Provider value={pageContext}>
      <div className="main-content">{children}</div>
    </PageContext.Provider>

    <Footer
      disableStickyFooter={disableStickyFooter(page)}
      context={pageContext}
    />
  </LayoutModals>
);
