import React from "react";
import Input from "../UncontrolledInput";

const StarsForm = ({ programs, token }) => {
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <div className="stars-form">
      <form
        method="POST"
        action="/stars"
        onSubmit={() => {
          setSubmitting(true);
        }}
      >
        <Input type="hidden" name="authenticity_token" value={token} />
        <Input type="hidden" name="stars[form_type]" value="regular" />

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="stars[first_name]"
              id="stars_first_name"
              label="First Name"
              required
              maxLength="12"
            />
          </div>

          <div className="form-item">
            <Input
              type="text"
              name="stars[last_name]"
              id="stars_last_name"
              label="Last Name"
              required
              maxLength="25"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="restirctiveText"
              name="stars[phone]"
              id="stars_phone"
              label="Phone"
              required
              maxLength="10"
              allowedCharacters={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ]}
              placeholder="Please enter a 10 digit phone number"
            />
          </div>

          <div className="form-item">
            <Input
              type="email"
              name="stars[email]"
              id="stars_email"
              label="Email"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="restirctiveText"
              name="stars[zip]"
              id="zip"
              label="Zip"
              required
              maxLength="5"
              allowedCharacters={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ]}
              placeholder="Please enter a 5 digit ZIP code"
            />
          </div>
        </div>

        <Input
          type="select"
          name="stars[program_1]"
          label="Program of interest"
          placeholder=""
          id="stars_program_1"
          options={programs}
          required
        />

        <Input
          type="checkbox"
          name="stars[terms]"
          required
          defaultValue="accept"
          labelClass="checkbox-label-grid"
          label={() => (
            <small>
              By clicking the submit button, I give AIMS Education permission to
              call, text, and/or email me information about its educational
              programs at the phone number and email address provided. I
              acknowledge my consent is not required to obtain any good or
              service. I can opt-out by contacting AIMS Education at
              908-222-0002.
            </small>
          )}
        />

        <div className="center">
          <button
            type="submit"
            className="btn-primary lg"
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default StarsForm;
