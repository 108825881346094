import { Container } from "@raketa-cms/raketa-cms";
import React from "react";
import AimsPrograms from "../frontend/Forms/AimsPrograms";
import InternationalStarsForm from "../frontend/Forms/InternationalStarsForm";
import COUNTRIES from "../helpers/COUNTRIES";
import AimsProgramsInput from "../inputs/AimsProgramsInput";
import { AuthTokenContext } from "../providers";

const StarsWidget = ({ list = [], containerSettings }) => {
  const token = React.useContext(AuthTokenContext);
  const programs = AimsPrograms.selectPrograms(list);

  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-3">
            <InternationalStarsForm
              token={token}
              programs={programs}
              countries={COUNTRIES}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

StarsWidget.title = "International Lead Form";
StarsWidget.category = "Forms";

StarsWidget.defaults = {
  containerSettings: {},
  list: AimsProgramsInput.defaults,
};

StarsWidget.adminFields = {
  list: { type: "custom", component: AimsProgramsInput },
};

export default StarsWidget;
