import React from "react";
import MiniFooter from "./MiniFooter";
import GlobalNotice from "./GlobalNotice";
import showGlobalNotice from "./showGlobalNotice";
import LayoutModals from "./LayoutModals";
import { PageContext } from "../providers/PageContext";

export default ({ page, pageContext, children }) => (
  <LayoutModals>
    {showGlobalNotice(page) && <GlobalNotice settings={pageContext.settings} />}

    <PageContext.Provider value={pageContext}>
      <div>{children}</div>
    </PageContext.Provider>

    <div className="footer">
      <MiniFooter context={pageContext} />
    </div>
  </LayoutModals>
);
